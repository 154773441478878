import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SafetyV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pb-50 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="about-us-info-wrap">
			          <div className="ltn__section-title-2--- mb-30">
			            <h2 className="ourofferings pb-10">The Safety of Your Drop Matters</h2>
			            <p className="pb-20">●	Have a Peace of mind while tracking your package in real-time on our app<br/>
						●	Safety features.<br/>
						●	Drop is built on Trust, we have confidence that our Droppers will use the app to make extra cash and nothing else.<br/>
						●	Our support is 24/7 using first and one of a kind in-app chat App, to chat with your Dropper or Drop support.<br/>
						</p>
			            <h2 className="ourofferings pb-30">Droppers</h2>
			            <h4 className="pb-20">Designing a Safer Experience For Our Droppers</h4>
						<h6> 1- An Inclusive Community</h6>
			            <p className="pb-10">Through our joint efforts with cities and safety experts and by working together, we’re helping to create safe journeys for everyone.</p>
						<h6> 2- 2way Ratings</h6>
			            <p className="pb-10">Your feedback matters. Low-rated Drop are logged, and users may be removed to protect the Drop community.</p>
						<h6> 3- Phone Anonymization</h6>
			            <p className="pb-10">If you need to contact your user through the app chat functionality, your phone number will always stay private.</p>
						<h6> 4- GPS Tracking</h6>
			            <p className="pb-10">All Drops are tracked from start to finish, so there’s a record of your journey if something happens.</p>
						<h6> 5- Personal Information</h6>
			            <p className="pb-10">Do not disclose any other personal information besides the information about the trip. Make sure to always use thein-appp chat functionality to communicate. All chats are recorded.</p>
						<h6> 6- Drop Check</h6>
			            <p className="pb-10">Using geolocation from GPS data, Drop Check will notify you if your Drop  as an unexpected long stop or heading to the wrong destination. </p>
						<h6> 7- Verify Your Identity</h6>
			            <p className="pb-10">You may need to take a selfie at the pickup location holding the package recipient before you can start the journey. Identity verification helps us make sure the right Dropper is the recipient getting their package. This will be recorded.</p>
						
						<h4 className="ourofferings pb-30">Designing a safer package send/receive</h4>
						<h2 className="ourofferings pb-30">Users</h2>
			            <p className="pb-20">Your safety is our priority. We never stop working to reduce incidents and set new standards, so you can stay connected and protected every time you use our delivery services.</p>
						<h6> 1- 2way Ratings</h6>
			            <p className="pb-10">Your feedback matters. You will be able to rate the dropper and give feedback on the service received.</p>
						<h6> 2- Phone Number Anonymization</h6>
			            <p className="pb-10">Drop app will not show your personal number to the dropper. We recommend users to always use the chat functionality.</p>
						<h6> 3- Drop Check</h6>
			            <p className="pb-10">We track the journey of your package using our app to keep you notified all the way. Drop Check can help detect if the drop has been dropped at the right location. If by any chance the package is not delivered within specified time make sure to chat to the Dropper, ask relevant questions, if you are not getting any response contact the support via the support chat function.</p>
						<h6> 4- Verify Dropper Identity</h6>
			            <p className="pb-10">When a Dropper arrives at the pickup location, be sure to verify his/her identity with the information of the app before handing over the package.</p>
						
						<h4 className="ourofferings pb-30">Droppers must clear several screenings</h4>
			            <p className="pb-20">All potential dropper-partners must complete a criminal background screening process before they can accept Drops requests through the Drop app.</p>
						<h5> Drop Hour Limits</h5>
			            <p className="pb-10">To help make everyone safe, All Drop pickups must be made visible for hours. They must communicate with the User if pickup will be late or outside visible hours.</p>
						<h5> Wrong Route Alerts</h5>
			            <p className="pb-10">The Drop app notification feature will notify the Dropper and the User to remind the Dropper if they are off route by specified kilometres.</p>
						<h5> Check your Dropper, every time</h5>
			            <p className="pb-10">Before your Dropper can pick up your package, take a second to double-check the app for your Dropper’s information. Follow these 3 steps to make sure you get into the right car:</p>
						<h6> Step 1</h6>
			            <p className="pb-10">Match the license plate number.</p>
						<h6> Step 2</h6>
			            <p className="pb-10">Match the vehicle make and model.</p>
						<h6> Step 3</h6>
			            <p className="pb-10">Check the driver photo.</p>
						<h6> Step 4</h6>
			            <p className="pb-10">Check if the Dropper is a public Dropper, verify the information on the app.</p>
						
						<h2 className="ourofferings pb-30">Everyone</h2>
			            <p className="pb-20">You deserve to be able to move safely. To look forward to the opportunities ahead. To be connected to people and places that matter most. This is why we’re focused on your safety, from setting new standards to developing technology with the goal of reducing incidents.</p>
						<h5> Helping to keep each other safe during COVID-19</h5>
			            <p className="pb-10">We’re actively monitoring the coronavirus (COVID-19) situation and are continually working to help keep those who rely on our platform healthy and safe.</p>
						
						<h4 className="ourofferings pb-30"> Read about our new Door-to-Door Safety Standard</h4>
						<h5> Hygiene supplies in your next Drop</h5>
			            <p className="pb-10">As part of our Door-to-Door Safety Standard, we’re working on ensuring high standards of hygiene. This includes all recipients carrying hygiene supplies like Hand Sanitiser and sanitizing their hands and the package upon receiving and pickup, so that everyone can feel safer.</p>
						<h5> How safety is part of our core value</h5>
						<h6> Together we work our community  </h6>
			            <p className="pb-10">Millions of Dropper and Users share a set of Community Guidelines, holding each other accountable to do the right thing.</p>
						
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default SafetyV1