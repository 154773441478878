import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class LocationV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__gallery-area mb-120">
				<div className="container">
					{/* (ltn__gallery-info-hide) Class for 'ltn__gallery-item-info' not showing */}
					<div className="ltn__gallery-active row ltn__gallery-style-2 ltn__gallery-info-hide---">
						<div className="ltn__gallery-sizer col-1" />
						<div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href="https://goo.gl/maps/iCXXDX59pDWatHkg6" data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/others/6.jpg"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-map-marker-alt" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top">Google Map</h4>
							<p>Complete Address or Live Location</p>
							</div>
						</div>
						</div>
						<div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href="https://goo.gl/maps/iCXXDX59pDWatHkg6" data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/others/6.jpg"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-map-marker-alt" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top">Google Map</h4>
							<p>Complete Address or Live Location</p>
							</div>
						</div>
						</div>
						<div className="ltn__gallery-item filter_category_1 col-lg-4 col-sm-6 col-12">
						<div className="ltn__gallery-item-inner">
							<div className="ltn__gallery-item-img">
							<a href="https://goo.gl/maps/iCXXDX59pDWatHkg6" data-rel="lightcase:myCollection">
								<img src={publicUrl+"assets/img/others/6.jpg"} alt="Image" />
								<span className="ltn__gallery-action-icon">
								<i className="fas fa-map-marker-alt" />
								</span>
							</a>
							</div>
							<div className="ltn__gallery-item-info">
							<h4 className="go-top">Google Map</h4>
							<p>Complete Address or Live Location</p>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
        }
}

export default LocationV1