import React from 'react';
import NavbarV2 from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import HowToDropV1 from './section-components/how-to-drop-v1';
import ApartmentV2 from './section-components/apartment-v2';
import AboutV1 from './section-components/about-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const HowToDrop = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="How to Drop?" subheader="How to Drop?" />
        <HowToDropV1 />
        <ApartmentV2 />
        <AboutV1 />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default HowToDrop

