import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ContactInfo extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__contact-address-area mb-90">
				<div className="container">
					<div className="col-lg-12">
						<div className="about-us-info-wrap">
						<div className="ltn__section-title-2--- mb-30">
							<h2 className="ourofferings wp-caption-text pb-30">Contact Us</h2>
						</div>
						</div>
					</div>
				<div className="row ">
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
						<h3 className="ourofferings wp-caption-text pb-10">Email Address</h3>
						<h4>Support Branch</h4>
						<p>support1@dropsa.co.za<br />
						   support2@dropsa.co.za</p>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
					<h3 className="ourofferings wp-caption-text pb-10">Email Address</h3>
						<h4>Deposit and Withdraw</h4>
						<p>deposit@dropsa.co.za<br />
						   withdraw@dropsa.co.za</p>
					</div>
					</div>
					<div className="col-lg-4">
					<div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
					<h3 className="ourofferings wp-caption-text pb-10">Contact Number</h3>
						<h4>All Queries Contacts</h4>
						<p>+27829576565</p>
					</div>
					</div>
				</div>
				
			</div>
			</div>
        }
}

export default ContactInfo