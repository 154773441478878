import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class DepositPolicyV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pb-90 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="about-us-info-wrap">
			          <div className="ltn__section-title-2--- mb-30">
			            <h2 className="ourofferings pb-30">Deposit and Withdraw Policy</h2>
			            <h3 className="ourofferings pb-20">User Deposit Policy</h3>
						<h5 className="pb-10">Adding a payment method</h5>
						<p className="pb-30">You can add or change your payment methods on My Wallet.<br/>
						To add a card, use the Deposit method option. When you add a new payment method, our system will validate it the first time you do a transaction and this shouldn't take a minute. If you experience an issue with adding your bank card, make sure:<br/>
						●	 You entered the correct card details<br/>
						●	 You have enough funds on your card<br/>
						●	 Your card is not expired<br/>
						●	 Your debit or credit card allows recurring e-commerce transactions<br/>
						●	 You are adding your MasterCard or Visa card.<br/>
 						</p>
			            <p className=" ourofferings pb-20">Please note that cash payment is not supported. However, you will be able to deposit the cash to your Wallet using banking eWallet service, EFT transfer or ATM deposit. </p>
						
						<h5 className="pb-10">Removing a payment method</h5>
						<p className="pb-20">To remove a payment method, swipe the line with a payment method to the left and click Delete.<br/>
						Keep in mind that you will be able to remove a payment method from the app only if there are no pending payments on your account.<br/>
						<br/>
 						</p>
						<h5 className="pb-10">Securing transaction information</h5>
						<p className="pb-20">We remain confident that access to the Drop app is only through your mobile device. To make the app secure you biometric access on your mobile device to protect the information on the Drop App.</p>
			            
						<h3 className="ourofferings pb-20">Withdrawal Policy</h3>
						<p className="pb-30">We understand the need to want to have your money instantly after your Drop. As soon as your Drop is finished the money is then transferred to My wallet. You can add your banking details to My wallet.<br/>
						For those without a bank account, Our team is working hard to allow Droppers to withdraw to a cash send or eWallet.<br/>
						To add banking details, use the Withdrawal method option. Once you have added your banking details, they will be saved for future withdrawal. If the is any delay or the money is not transferred within 2 working days, make sure to check if you entered the correct banking details<br/>
						●	Your bank account is correct<br/>
						●	Your bank accepts payment from South African banks<br/>
						●	Your debit or credit card allows recurring e-commerce transactions<br/>
 						</p>
					  </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default DepositPolicyV1