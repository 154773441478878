import React from 'react';
import Navbar2 from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import HowToDrop from './section-components/how-to-drop-v1';
import Faq from './section-components/faq-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const FaqV1 = () => {
    return <div>
        <Navbar2 />
        <PageHeader headertitle="FAQ’s" subheader="FAQ’s" />
        <HowToDrop />
        <Faq />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default FaqV1

