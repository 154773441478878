import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class DropCopyrightV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pb-90 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="about-us-info-wrap">
			          <div className="ltn__section-title-2--- mb-30">
			            <h2 className="ourofferings pb-30">Copyright Policy Notification of Copyright Infringement</h2>
			            <p className="pb-20">Drop respects the intellectual property rights of others and expects its users to do the same.</p>
						<p className="pb-10">"It is Drop’s policy, in appropriate circumstances and at its discretion, to disable and/or terminate the accounts of users who repeatedly infringe or are repeatedly charged with infringing the copyrights or other intellectual property rights of others.
						In accordance with the Digital Millennium Copyright Act of 1998 and in recognition of various international copyright laws, Drop will respond expeditiously to notices sent to Drop’s Designated Copyright Agent (identified below) regarding alleged third-party copyright infringements on the Drop website or other Drop-owned and publicly accessible digital properties (the "Site(s)").<br />
						A copyright owner, person authorized to act on behalf of one, or person authorized to act under any exclusive right under copyright may submit allegations of copyright infringements on the Site(s) by completing the following <b> DMCA </b> Notice of Alleged Infringement and delivering it to Drop’s Designated Copyright Agent. Upon receipt of this Notice, Drop will expeditiously remove or block access to the allegedly infringing content, notify the alleged third-party infringer of the same, and provide the third-party an opportunity to submit a counter-notice to dispute the alleged infringement. If a counter-notice is submitted, Drop will promptly forward it to the complainant and restore the removed or blocked content within 10-14 business days, unless the complainant submits evidence that it has filed a lawsuit against the alleged third-party infringer regarding the allegedly infringing content.
						"</p>
						<h2 className="ourofferings pb-30">DMCA Notice of Alleged Infringement ("Notice")</h2>
			            <p className="pb-10">1. Identify the copyrighted work that has been infringed, or - if multiple copyrighted works are covered by this Notice - provide a representative list of the copyrighted works that have been infringed.</p>
			            <p className="pb-10">2. Identify the infringing content and provide sufficient information for Drop to locate the infringing content, including, if applicable, the URL(s) of the Site(s) where the content may be found.</p>
			            <p className="pb-10">3. Provide contact information, e.g., mailing address, telephone number, and email address, of the person submitting this DMCA Notice (e.g., the copyright owner or copyright owner’s agent).</p>
			            <p className="pb-10">4. Include both of the following statements in the body of the Notice: <br />
							●	"I hereby state that I have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use)." <br />
							●	"I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed." </p>
						<p className="pb-10">"5. Provide the full legal name and an electronic or physical signature of the person submitting this Notice (e.g., the copyright owner or copyright owner’s agent)."</p>
						<p className="pb-20">"Copyright Agent"</p>
						<p className="pb-10">" c/o Drop Technologies, Inc.<br />
							1515 3rd Street<br />
							San Francisco, CA 94158<br />
							takedowns [at] Drop [dot] com<br />
							"</p>
							<p className="pb-10">IMPORTANT: Communications unrelated to copyright use or infringement will be discarded. Please follow this link for Drop’s Guidelines for Third Party Data Requests and Service of Legal Documents</p>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default DropCopyrightV1