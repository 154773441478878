import React from 'react';
import NavbarV2 from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import HowToDrop from './section-components/how-to-drop-v1';
import SafetyV1 from './section-components/safety-v1';
import SafetyFeaturesV1 from './section-components/safety-features-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const WhyDropApp = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="Safety" subheader="Safety" />
        <HowToDrop />
        <SafetyV1 />
        <SafetyFeaturesV1 />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default WhyDropApp

