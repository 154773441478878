import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Map extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__gallery-area mb-20">
				<div className="container">
					<div className="row">
					<div className="col-lg-12">
						<div className="about-us-info-wrap">
						<div className="ltn__section-title-2--- mb-10">
							<h2 className="ourofferings wp-caption-text pb-30">Office Locations</h2>
						</div>
						</div>
					</div>
					</div>
				</div>
			</div>
        }
}

export default Map