import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="ltn__about-us-area pt-50 pb-100 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-img-wrap about-img-left">
			          <img src={publicUrl+"assets/img/others/115.png"} alt="For Our Droper" />
			        </div>
			      </div>
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-info-wrap">
			          <div className="section-title-area ltn__section-title-2--- mb-30">
			            <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">How to be a Dropper</h6>
			            <h1 className="section-title">How to Sign Up and Become a Dropper</h1>
			          </div>                        
			          <div className="ltn__feature-item ltn__feature-item-3">
			            <div className="ltn__feature-icon">
			              <span><b><i className="flaticon"/>1</b></span>
			            </div>
			            <div className="ltn__feature-info">
			              <h4><a href="/">Login/Signup</a></h4>
			              <p>Download the Drop Driver app on your iPhone and Android and sign up using your email address or cell phone number and start dropping packages.</p>
			            </div>
			          </div>
			          <div className="ltn__feature-item ltn__feature-item-3">
			            <div className="ltn__feature-icon">
						<span><b><i className="flaticon"/>2</b></span>
			            </div>
			            <div className="ltn__feature-info">
			              <h4><a href="/">Registration</a></h4>
			              <p>Private - As a private vehicle owner you are allowed to register with valid documents and wait for verification. <br/>
							Public - Drop’s mission is to allow anyone to make money, if you don’t own a private vehicle, you can always register as a public dropper and start making Drops and save money.
							</p>
			            </div>
			          </div>
			          <div className="ltn__feature-item ltn__feature-item-3">
			            <div className="ltn__feature-icon">
						<span><b><i className="flaticon"/>3</b></span>
			            </div>
			            <div className="ltn__feature-info">
			              <h4><a href="/">Verification</a></h4>
			              <p>The verification process for all droppers takes less than a day, once all your documents are submitted and you get both SMS and email and start dropping.</p>
			            </div>
			          </div>
					  <div className="ltn__feature-item ltn__feature-item-3">
			            <div className="ltn__feature-icon">
						<span><b><i className="flaticon"/>4</b></span>
			            </div>
			            <div className="ltn__feature-info">
			              <h4><a href="/">Planning a Drop</a></h4>
			              <p>One of the unique Drop features is to allow Droppers to plan their trip on the app and get Drop request recommendations based on their location and destination. </p>
			            </div>
			          </div>
					  <div className="ltn__feature-item ltn__feature-item-3">
			            <div className="ltn__feature-icon">
						<span><b><i className="flaticon"/>5</b></span>
			            </div>
			            <div className="ltn__feature-info">
			              <h4><a href="/">Accepting Drops</a></h4>
			              <p>The key feature of Drop is to allow Dropper to bid for Drops and send them back to the user, then the user accepts the bit amount and the journey starts.</p>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default AboutV3