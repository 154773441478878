import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ApartmentV2 extends Component {

    render() {

	let CustomClass = this.props.customClass ? this.props.customClass : ''

    let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className={"ltn__apartments-plan-area pt-115--- "+ CustomClass}>
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">For Our Users</h6>
			          <h1 className="section-title">HOW TO REQUEST FOR DROP</h1>
			        </div>
			        <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center">
			          <div className="nav">
			            <a className="active show" data-bs-toggle="tab" href="#liton_tab_3_1">Login / Signup</a>
			            <a data-bs-toggle="tab" href="#liton_tab_3_2">Send Request</a>
			            <a data-bs-toggle="tab" href="#liton_tab_3_3">Dropper Response</a>
			            <a data-bs-toggle="tab" href="#liton_tab_3_4">Package Dropped</a>
			          </div>
			        </div>
			        <div className="tab-content">
			          <div className="tab-pane fade active show" id="liton_tab_3_1">
			            <div className="ltn__apartments-tab-content-inner">
			              <div className="row">
			                <div className="col-lg-6">
			                  <div className="apartments-plan-info ltn__secondary-bg text-color-white">
			                    <h2>Login / Signup</h2>
			                    <p>Download the Drop app on your iPhone and Android and sign up using your email address or cell phone number and request your package to be dropped.</p>
			                  </div>
			                </div>
			                <div className="col-lg-6">
			                  <div className="apartments-plan-img">
			                    <img src={publicUrl+"assets/img/others/111.png"} alt="#" />
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>
			          <div className="tab-pane fade" id="liton_tab_3_2">
			            <div className="ltn__apartments-tab-content-inner">
			              <div className="row">
			                <div className="col-lg-6">
			                  <div className="apartments-plan-info ltn__secondary-bg text-color-white">
			                    <h2>Send Request</h2>
			                    <p>Get your packages or parcels wrapped or in a box nicely, go to request for Drop, add destination location and pickup location, and upload pictures, droppers bid for your request.</p>
			                  </div>
			                </div>
			                <div className="col-lg-6">
			                  <div className="apartments-plan-img">
			                    <img src={publicUrl+"assets/img/others/112.png"} alt="#" />
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>
			          <div className="tab-pane fade" id="liton_tab_3_3">
			            <div className="ltn__apartments-tab-content-inner">
			              <div className="row">
			                <div className="col-lg-6">
			                  <div className="apartments-plan-info ltn__secondary-bg text-color-white">
			                    <h2>Dropper Response</h2>
			                    <p>The dropper will accept your request and come to your location and pick up your package, and the journey starts.</p>
			                  </div>
			                </div>
			                <div className="col-lg-6">
			                  <div className="apartments-plan-img">
			                    <img src={publicUrl+"assets/img/others/113.png"} alt="#" />
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>
			          <div className="tab-pane fade" id="liton_tab_3_4">
			            <div className="ltn__apartments-tab-content-inner">
			              <div className="row">
			                <div className="col-lg-6">
			                  <div className="apartments-plan-info ltn__secondary-bg text-color-white">
			                    <h2>Package Dropped</h2>
			                    <p>A recipient sign-off the drop and funds get transferred to the dropper’s wallet and you get to rate the journey.</p>
			                  </div>
			                </div>
			                <div className="col-lg-6">
			                  <div className="apartments-plan-img">
			                    <img src={publicUrl+"assets/img/others/114.png"} alt="#" />
			                  </div>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default ApartmentV2