import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class BannerV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <div className="ltn__slider-area ltn__slider-11  ltn__slider-11-slide-item-count-show--- ltn__slider-11-pagination-count-show--- section-bg-1 ">
			  <div className="ltn__slider-11-inner">
			    <div className="ltn__slider-11-active">
			      {/* slide-item */}
			      <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
			        <div className="ltn__slide-item-inner">
			          <div className="container">
			            <div className="row">
			              <div className="col-lg-12 align-self-center">
			                <div className="slide-item-info">
			                  <div className="slide-item-info-inner ltn__slide-animation">
			                    <h1 className="slide-title animated ">Couriers With No<br />Middleman</h1>
			                    <div className="slide-brief animated">
			                      <p>Earn extra cash while you are on your journey, Drop allows anyone to make money, and drop people’s packages while using public transport.</p>
			                    </div>
			                    <div className="btn-wrapper animated">
			                      <a href="https://play.google.com" target="_blank" className="theme-btn-1 btn btn-effect-1">DOWNLOAD</a>
			                      <Link to="/about-drop" className="btn btn-transparent btn-effect-3"> LEARN MORE</Link>
			                    </div>
			                  </div>
			                </div>
			                <div className="slide-item-img">
			                  <img src={publicUrl+"assets/img/slider/slider-1.png"} alt="#" />
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

        }
}

export default BannerV2