import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CallToActonV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__call-to-action-area call-to-action-6 before-bg-bottom" >
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="call-to-action-inner call-to-action-inner-6 section-bg-7 position-relative text-center---">
			          <div className="coll-to-info text-color-white col-lg-6 col-sm-6">
			            <h3 col-lg-6 col-sm-6 col-12>Download Drop App</h3>
			          </div>
			          <div className="btn-wrapper go-top col-lg-2 col-sm-6">
			            <Link className="btn-effect-4" to="/"><img src={process.env.PUBLIC_URL + '/assets/img/icons/svg/apps/app-store.svg'} alt="#"/></Link>
			          </div>
					  <div className="btn-wrapper go-top col-lg-2 col-sm-6">
			            <Link className="btn-effect-4" to="/"><img src={process.env.PUBLIC_URL + '/assets/img/icons/svg/apps/play-store.svg'} alt="#"/></Link>
			          </div>
					  <div className="btn-wrapper go-top col-lg-2 col-sm-6">
			            <Link className="btn-effect-4" to="/"><img src={process.env.PUBLIC_URL + '/assets/img/icons/svg/apps/huawei.svg'} alt="#"/></Link>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default CallToActonV1