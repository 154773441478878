import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class OurOfferingsV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pb-90 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="about-us-info-wrap">
					<div className="ltn__section-title-2--- mb-30">
			            <h2 className="ourofferings pb-30">Drop Technology Offerings</h2>
			            <h4 className="ourofferings pb-20">Drop user apps, Packaging and Boxes, and other offerings</h4>
						<p className="pb-30">Drop technologies mission is to change the traditional way courier services operate. Our App trip recommendation engine offers Droppers looking for Drops based on the trip they plan, we are an independent provider of mobility as a service. Planning on going somewhere tomorrow, anywhere? Plan your trip on the app and we will recommend drops for you along the way, you don't have to change your course.</p>
			            <h5 className=" pb-10">Making extra earning with Drop</h5>
						<p className="pb-20">The opportunity is everywhere. Make Drop anytime you need cash</p>
			            <h5 className=" pb-10">Access to mobility</h5>
						<p className="pb-20">Helping to improve rural areas, villages access to mobility in need.</p>
			            <h5 className=" pb-10">Same-day Drop</h5>
						<p className="pb-20">Very convenient solution that allows anyone to send and receive packages the same day.</p>
			            <h5 className=" pb-10">Safety</h5>
						<p className="pb-20">Our commitment to the safety of your packages goes to the core of our solution.</p>
			            <h5 className=" pb-10">Country</h5>
						<p className="pb-20">Available in the following countries.</p>
						
						<h3 className=" ourofferings pb-20">Improving cities together</h3>
						<h5 className="pb-10">Helping to improve mobility for all</h5>
						<p className="pb-20">Drop is committed to connecting cities and villages in the rural and urban areas make public mobility even more accessible, equitable, and efficient. We urge everyone who travels frequently across cities to register as a dropper and start making money while helping people.</p>
			            <h5 className=" pb-10">Helping businesses move ahead</h5>
						<p className="pb-30">Drop App can also be used by merchant and store owners for shipping and moving stock or goods to different destinations. </p>
					  </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default OurOfferingsV1