import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class StoryV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pb-90 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-info-wrap">
			          <div className="section-title-area ltn__section-title-2--- mb-30">
			            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Story</h6>
			            <h3>Many South Africans can relate their experience to the story. </h3>
			            <p>The drop came as an idea when the Founder Sipho Mangena 31-years software engineer realized how hard it is to send and receive packages from a village in Limpopo, the courier industry does deliver in rural areas, but it is difficult to find one that sends from rural areas.  We often use taxi drivers or passengers to give packages for delivery and have someone waiting on a taxi rank for the taxi to arrive and receive the package and pay the driver or passenger.  <br />
						Drop is the first South-African app that connects all people from rural and urban areas, with the mission to make the accessibility of sending and receiving packages more affordable, safe, and sustainable. We help people save time and money, reduce stress, and improve cities. The drop also empowers millions of South African — drivers, couriers, public transport users, restaurant, and store owners to start using drop now.
						</p>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-img-wrap about-img-right">
			          <img src={publicUrl+"assets/img/others/119.png"} alt="About Us Image" />
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default StoryV2