import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class AllCitiesV1 extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'

    let customClass = this.props.customClass ? this.props.customClass :''

    return <div className={ customClass } >
			  <div className="container pb-50">
			  <div className="row">
			      <div className="col-lg-12">
			        <div className="about-us-info-wrap">
			          <div className="ltn__section-title-2--- mb-30">
			            <h2 className="ourofferings pb-50">Drop In All Over the South Africa</h2>
			          </div>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__custom-gutter--- justify-content-center go-top">
			      <div className="col-lg-3 col-sm-4 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			            <div className="ltn__feature-info">
			            <h4>All cities</h4>
			          </div>
			        </div>
			      </div>
			    </div>
				<div className="row">
			      <div className="col-lg-12">
			        <div className="about-us-info-wrap">
			          <div className="ltn__section-title-2--- mt-30 mb-30">
			            <h2 className="ourofferings pb-50">Drop In Zimbabwe</h2>
			          </div>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__custom-gutter--- justify-content-center go-top">
			      <div className="col-lg-3 col-sm-4 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			            <div className="ltn__feature-info">
			            <h4>Bulawayo</h4>
			          </div>
			        </div>
			      </div>
				  <div className="col-lg-3 col-sm-4 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			            <div className="ltn__feature-info">
			            <h4>Harare</h4>
			          </div>
			        </div>
			      </div>
			    </div>
				<div className="row">
			      <div className="col-lg-12">
			        <div className="about-us-info-wrap">
			          <div className="ltn__section-title-2--- mt-30 mb-30">
			            <h2 className="ourofferings pb-50">Drop In Botswana</h2>
			          </div>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__custom-gutter--- justify-content-center go-top">
			      <div className="col-lg-3 col-sm-4 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			            <div className="ltn__feature-info">
			            <h4>Gaborone</h4>
			          </div>
			        </div>
			      </div>
			    </div>
				<div className="row">
			      <div className="col-lg-12">
			        <div className="about-us-info-wrap">
			          <div className="ltn__section-title-2--- mt-30 mb-30">
			            <h2 className="ourofferings pb-50">Drop In Lesotho</h2>
			          </div>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__custom-gutter--- justify-content-center go-top">
			      <div className="col-lg-3 col-sm-4 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			            <div className="ltn__feature-info">
			            <h4>Maseru</h4>
			          </div>
			        </div>
			      </div>
			    </div>
				<div className="row">
			      <div className="col-lg-12">
			        <div className="about-us-info-wrap">
			          <div className="ltn__section-title-2--- mt-30 mb-30">
			            <h2 className="ourofferings pb-50">Drop In Mozambique</h2>
			          </div>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__custom-gutter--- justify-content-center go-top">
			      <div className="col-lg-3 col-sm-4 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			            <div className="ltn__feature-info">
			            <h4>Maputo</h4>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default AllCitiesV1