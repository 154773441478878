import React from 'react';
import NavbarV2 from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import HowToDrop from './section-components/how-to-drop-v1';
import DepositPolicyV1 from './section-components/deposit-policy-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const DepositPolicy = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="Deposit Policy" subheader="Deposit Policy" />
        <HowToDrop />
        <DepositPolicyV1 />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default DepositPolicy

