import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HowToDropV1 extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'

    let customClass = this.props.customClass ? this.props.customClass :''

    return <div className={ customClass } >
			  <div className="container pb-80">
				<div className="row">
					<div className="col-lg-12">
						<div className="about-us-info-wrap">
						<div className="ltn__section-title-2--- mb-30">
							<h2 className="ourofferings wp-caption-text pb-30">Download User App Now</h2>
						</div>
						</div>
					</div>
					</div>
			    <div className="row ltn__custom-gutter--- justify-content-center go-top">
			      <div className="col-lg-5 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/logo.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h4><a href="https://play.google.com" target="_blank">Download User App for Android</a></h4>
			          </div>
			        </div>
			      </div>
				  <div className="col-lg-5 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/logo.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h4><a href="https://play.google.com" target="_blank">Download User App for IOS</a></h4>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default HowToDropV1