import React from 'react';
import Navbar2 from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import AboutV2 from './section-components/about-v2';
import StoryV2 from './section-components/story-v2';
import Features from './section-components/features-v1';
import Cateogory from './section-components/category-v1';
import TestimonialV2 from './section-components/testimonial-v2';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const About_v1 = () => {
    return <div>
        <Navbar2 />
        <PageHeader headertitle="About Drop" />
        <AboutV2 />
        <Features  customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---"/>
        <Cateogory />
        <StoryV2 />
        <TestimonialV2 />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default About_v1

