import React from 'react';
import NavbarV2 from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import HowToDrop from './section-components/how-to-drop-v1';
import TermsAndConditionsV1 from './section-components/terms-and-Conditions-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const TermsAndConditions = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="Terms and Conditions" subheader="Terms and Conditions" />
        <HowToDrop />
        <TermsAndConditionsV1 />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default TermsAndConditions

