import React from 'react';
import NavbarV2 from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import OurOfferingsV1 from './section-components/our-offerings-v1';
import Cateogory from './section-components/category-v1';
import Features from './section-components/features-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const OurOfferings = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="Our Offerings" subheader="Our offerings" />
        <OurOfferingsV1 />
        <Features  customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---"/>
        <Cateogory />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default OurOfferings

