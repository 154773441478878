import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class FeaturesV1 extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'

    let customClass = this.props.customClass ? this.props.customClass :''

    return <div className={ customClass } >
			  <div className="container">

			    <div className="row ltn__custom-gutter--- justify-content-center go-top">
			      <div className="col-lg-5 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/logo.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h4><a href="https://play.google.com" target="_blank">Download User App for Android</a></h4>
			          </div>
			        </div>
			      </div>
				  <div className="col-lg-5 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/logo.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h4><a href="https://play.google.com" target="_blank">Download Driver App for Android</a></h4>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default FeaturesV1