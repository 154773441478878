import React from 'react';
import Navbar from './global-components/navbar-v2';
import Banner from './section-components/banner-v2';
import ApartmentV2 from './section-components/apartment-v2';
import Aboutv3 from './section-components/about-v3';
import Cateogory from './section-components/category-v1';
import Aboutv1 from './section-components/about-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const Home_V2 = () => {
    return <div>
        <Navbar />
        <Banner />
        <ApartmentV2 />
        <Aboutv3 />
        <Cateogory />
        <Aboutv1 />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default Home_V2

