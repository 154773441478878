import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pb-90 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-info-wrap">
			          <div className="section-title-area ltn__section-title-2--- mb-30">
			            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Drop</h6>
			            <h1 className="section-title">About Drop Company</h1>
			            <p>Drop is mobility as a service provider that connects people who want to send or receive packages in real-time. Our app connects all people from different places even those in rural areas and villages to be able to send or receive packages effortlessly and efficiently. <br/>
						If you want to make extra money along the way of your journey why not do it in style and drop a few packages along the way. 
						</p>
			          </div>
			          <ul className="ltn__list-item-1 ltn__list-item-1-before clearfix">
			            <li>Drop is 100% Trusted</li>
			            <li>Set Your Own Price</li>
			            <li>Secure and Safe</li>
			            <li>Fast and Convenient Delivery</li>
			            <li>24/7 Chat Support</li>
			            <li>No Extra Hidden Costs</li>
			          </ul>
			        </div>
			      </div>
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-img-wrap about-img-right">
			          <img src={publicUrl+"assets/img/others/117.png"} alt="About Us Image" />
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default AboutV2