import React from 'react';
import NavbarV2 from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import ContactInfo from './section-components/contact-info';
import HowToDrop from './section-components/how-to-drop-v1';
import Map from './section-components/map';
import LocationV1 from './section-components/Location-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const ContactV1 = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="Contact Us" subheader="Contact Us" />
        <HowToDrop />
        <ContactInfo />
        <Map />
        <LocationV1 />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default ContactV1

