import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area">
			  <div className="container">
			    <div className="row">
				<div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">For Payments</h6>
			          <h1 className="section-title">HOW TO WITHDRAW FROM DROP</h1>
			        </div>
				</div>
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-info-wrap">
			          <div className="section-title-area ltn__section-title-2--- pb-80">
			            <h3 className=" ltn__secondary-color pb-20">Drop Withdrawal</h3>
			            <p className="section-subtitle section-subtitle-2--- ltn__secondary-color">A Dropper can use Drop My Wallet to withdraw his funds to any bank account anytime, anywhere instantly.</p>
			          </div>
			            <div className="btn-wrapper animated go-top">
			            <Link to="/about-drop" className="theme-btn-1 btn btn-effect-1">Learn More</Link>
			          </div>
			        </div>
			      </div>
				  <div className="col-lg-6 align-self-center">
			        <div className="about-us-img-wrap about-img-left">
			          <img src={publicUrl+"assets/img/others/120.png"} alt="About Us Image" />
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default AboutV1