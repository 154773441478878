import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class WhyDropAppV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pb-90 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="about-us-info-wrap">
			          <div className="ltn__section-title-2--- mb-30">
					  <h2 className="ourofferings pb-30">Why Dropp</h2>
			            <h4 className="pb-20">Courier with No Middle Man</h4>
						<p> Earn extra cash while you are on your journey, Drop allows anyone to make money, and drop people’s packages while using public transport.<br/><br/>
						●	Drop is 100% Trusted <br/>
						●	Set your own price<br/>
						●	Secure and Safe<br/>
						●	Fast and Convenient Delivery<br/>
						●	24/7 chat support<br/>
						●	No extra hidden costs
						</p>

						<h3 className="ourofferings pb-20">Drop User App</h3>
			            <p className="pb-10">●	Request for a Drop anywhere at any time.<br/>
						●	Choose from a dropper of your choice.<br/>
						●	Accept bids for your Drop from different Droppers<br/>
						●	Very easy to use App.<br/>
						●	Request to Drop anything of any size.<br/>
						●	Real-time geolocation tracking.<br/><br/>
						The drop doesn’t charge hidden costs, it only charges a percentage on the single transaction, which means the user doesn't get extra charges and the dropper doesn't also get charged extra. We charge only on the single transaction performed between the user and dropper.
						</p>

						<h4 className="pb-20">The Safety of Your Drop Matters</h4>
			            <p className="pb-10">●	Have a Peace of mind while tracking your package in real-time on our app<br/>
						●	Safety features.<br/>
						●	Drop is built on Trust, we have confidence that our Droppers will use the app to make extra cash and nothing else.<br/>
						●	Our support is 24/7 using first and one of a kind in-app chat App, to chat with your Dropper or Drop support.<br/>
						</p>

						<h3 className="ourofferings pb-20">Drop Driver App</h3>
			            <p className="pb-10">Are you going somewhere? Why not make money along the way?<br/><br/>
							Plan your journey and pick a few drops along the way. Plan your trip and drop will send you drops aligned with your journey and make money along the way.
						</p>
						
						<h4 className="pb-20">Don't Own A Vehicle? No Problem</h4>
			            <p className="pb-10">You can register as a public Dropper and use public transport to make Drops. Very easy to use. <br/><br/>
						●	Simply plan your trip and get recommended. <br/>
						●	You will have 24/7 support via our in-App chat.<br/>
						●	Chat with the user throughout the journey.<br/>
						</p>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default WhyDropAppV1