import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class SafetyFeaturesV1 extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'

    let customClass = this.props.customClass ? this.props.customClass :''

    return <div className={ customClass } >
			  <div className="container pb-90">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h2 className="ourofferings">How Safety is Built into Your Experience</h2>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__custom-gutter--- justify-content-center go-top">
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-info">
			            <h3>Strengthening Our Community</h3>
			            <p>We want to make Drop enjoyable and safe for everyone. Anyone who doesn’t follow our Community Guidelines will be removed and blocked from the platform. We encourage people to use the app accordingly to make extra money</p>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
			          <div className="ltn__feature-info">
			            <h3>Safer journeys For Everyone</h3>
			            <p>You deserve to be able to move safely. Our commitment to safety goes beyond the app. We have distributed our perfect sealed packaging and boxes in nearest accessible stores to make your package safe and secured.</p>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-info">
			            <h3>Partnering With Public Safety Officials </h3>
			            <p>Our investigative team of former law enforcement professionals is always ready to help public safety officials resolve active cases. We do not compromise when it comes to criminal and unlawful offenses.</p>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default SafetyFeaturesV1