import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CategoryV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <div className="ltn__category-area ltn__product-gutter section-bg-1--- pt-30 pb-110 go-top">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Vehicles</h6>
			          <h2 className="section-title">Vehicles Categories</h2>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center">
			      <div className="col-lg-2 col-md-3 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 text-center">
			          <Link to="#">
			            <span className="category-icon"><img src={process.env.PUBLIC_URL + '/assets/img/icons/svg/categories/Private.svg'} alt="#"/></span>
			            <span className="category-title">Private</span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-2 col-md-3 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 text-center">
			          <Link to="#">
			            <span className="category-icon"><img src={process.env.PUBLIC_URL + '/assets/img/icons/svg/categories/Truck.svg'} alt="#"/></span>
			            <span className="category-title">Truck</span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-2 col-md-3 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 text-center">
			          <Link to="#">
			            <span className="category-icon"><img src={process.env.PUBLIC_URL + '/assets/img/icons/svg/categories/bakkie.svg'} alt="#"/></span>
			            <span className="category-title">Bakkie</span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-2 col-md-3 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 text-center">
			          <Link to="#">
			            <span className="category-icon"><img src={process.env.PUBLIC_URL + '/assets/img/icons/svg/categories/Public.svg'} alt="#"/></span>
			            <span className="category-title">Public</span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-2 col-md-3 col-sm-6 col-6">
			        <div className="ltn__category-item ltn__category-item-5 text-center">
			          <Link to="#">
			            <span className="category-icon"><img src={process.env.PUBLIC_URL + '/assets/img/icons/svg/categories/Scooters.svg'} alt="#"/></span>
			            <span className="category-title">Scooters</span>
			          </Link>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default CategoryV1