import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TermsAndConditionsV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pb-90 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="about-us-info-wrap">
					<div className="ltn__section-title-2--- mb-30">
			            <h2 className="ourofferings pb-30">Terms and Conditions</h2>
			            <h3 className="ourofferings pb-20">Contractual Relationship</h3>
						<p className="pb-30">These Terms of Use govern the access or use by you, an individual, from within any country in Africa of applications, websites, content, products, and services made available by Drop Technologies, a private limited company established in Johannesburg South Africa , having its offices at Louwlardia, Centurion, Pretoria , registered at CIPC under the number 2022/385818/07.<br/>
						If there is any provision in these Terms and Conditions that you do not understand, it is your responsibility to ask Drop Technologies to explain it to you before you accept the Terms and Conditions or continue using the Website<br/>
						Your access and use of the Services constitutes your agreement to be bound by these Terms, which establishes a contractual relationship between you and Drop Technologies. If you do not agree to these Terms, you may not access or use the Services. Drop technologies may immediately terminate these Terms or any Services with respect to you, or generally cease offering or deny access to the Services or any portion thereof, at any time for any reason.<br/>
 						</p>
						 <h3 className="ourofferings pb-20">The Services</h3>
						<p className=" pb-20">The Services constitute a technology platform that enables users of Drop’s mobile applications or websites provided as part of the Services to arrange and schedule drops services with independent third party providers of such services, including independent third-party transportation providers and independent third party logistics providers under agreement with Drop Technologies.</p>

						<h3 className="ourofferings pb-20">Registration and the use of the Drop app</h3>
						<p className="pb-20">Only registered users may use the drop application to drop or receive goods. To register as a user, you must provide a unique username and password and provide certain information and personal details to Drop. You will need to use your unique username and password to access Drop in order to drop or receive goods. You agree that, once the correct username and password relating to your account have been entered, irrespective of whether the use of the username and password is unauthorised or fraudulent, you will be liable for payment of such service requested. You agree to notify Drop technologies immediately upon becoming aware of or reasonably suspecting any unauthorised access to or use of your username and password and to take steps to mitigate any resultant loss or harm. By using Drop you warrant that you are 18 (eighteen) years of age or older and of full legal capacity.
 						</p>

						<h5 className="pb-10">Payment</h5>
						<p className="pb-20">We are committed to providing secure online payment facilities. All transactions are encrypted using appropriate encryption technology. Payment may be made for Goods via the following methods:<br/><br/>
						●	Instant EFT<br/>
						●	Card - where payment is made by card, we may require additional information in order to authorize and/or verify the validity of payment. In such cases we are entitled to withhold drops until such time as the additional information is received by us and authorization is obtained by us for the amounts. If we do not receive authorization your order for the Goods will be canceled. You warrant that you are fully authorized to use the card supplied for purposes of paying for the Goods. The drop will not accept your order if payment has not been received.<br/><br/>
						You may contact us via our Support chat functionality to obtain a full record of your payment. We will also send you email communications about your order and payment. <br/>
						</p>
					  </div> 
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default TermsAndConditionsV1