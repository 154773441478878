import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeV2 from './components/home-v2';

import About from './components/about-drop';
import OurOfferings from './components/our-offerings';
import WhyDropApp from './components/why-drop-app';
import AllCities from './components/all-cities';
import HowToDrop from './components/how-to-drop';
import HowToBeDriver from './components/how-to-be-driver';
import Safety from './components/safety';
import TermsAndConditions from './components/terms-and-Conditions';
import PrivacyPolicy from './components/privacy-policy';
import DepositPolicy from './components/deposit-policy';
import DropCopyright from './components/drop-copyright';
import Faq from './components/faq';
import ComingSoon from './components/coming-soon';
import Error  from './components/404';



import Contact from './components/contact';


class Root extends Component {
    render() {
        return(
                <Router basename="/">
	                <div>
	                <Switch>
                        <Route exact path="/" component={HomeV2} />

                        <Route path="/about-drop" component={About} />
                        <Route path="/our-offerings" component={OurOfferings} />
                        <Route path="/why-drop-app" component={WhyDropApp} />
                        <Route path="/all-cities" component={AllCities} />
                        <Route path="/how-to-drop" component={HowToDrop} />
                        <Route path="/how-to-be-driver" component={HowToBeDriver} />
                        <Route path="/safety" component={Safety} /> 
                        <Route path="/terms-and-conditions" component={TermsAndConditions} />
                        <Route path="/privacy-policy" component={PrivacyPolicy} />
                        <Route path="/deposit-policy" component={DepositPolicy} />
                        <Route path="/drop-copyright" component={DropCopyright} />
                        <Route path="/faq" component={ Faq } />
                        <Route path="/coming-soon" component={ ComingSoon } />
                        <Route path="/contact" component={ Contact } />
                        <Route path="/404" component={ Error } />
	                </Switch>
	                </div>
                </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('drop'));
