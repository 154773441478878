import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PrivacyPolicyV1 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pb-90 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="about-us-info-wrap">
			          <div className="ltn__section-title-2--- mb-30">
			            <h2 className="ourofferings pb-30">Our Privacy Policy</h2>
			            <p className="pb-20">When you use Drop services, you trust us with your personal data. We’re committed to keeping that trust, and that starts with helping you understand our privacy practices.<br/><br/>
						●	We maintain the value of personal data for Drop and our users by handling data as users expect, keeping it accurate and complete, and properly destroying it when it is no longer needed.<br/>
						●	We perform privacy reviews on new and changed products, technologies, and services to make sure they fulfill users’ expectations.<br/>
						●	We collect and use only the amount of personal data we need for approved and lawful purposes.<br/>
						●	We provide reasonable and appropriate safeguards to prevent loss, and unauthorized use or disclosure, of personal data.<br/>
						●	For data collected to find your location, we use it to help riders and drivers find each other, determine the best routes, enable users to track their progress and share their trip status with friends, develop new features and services, and enhance safety and security.<br/>
						</p>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default PrivacyPolicyV1