import React from 'react';
import NavbarV2 from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import HowToBeDriverV1 from './section-components/how-to-be-driver-v1';
import AboutV3 from './section-components/about-v3';
import AboutWithdrawV1 from './section-components/about-withdraw-v1';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

const HowToBeDriver = () => {
    return <div>
        <NavbarV2 />
        <PageHeader headertitle="How to Be Driver?" subheader="How to Be Driver?" />
        <HowToBeDriverV1 />
        <AboutV3 />
        <AboutWithdrawV1 />
        <CallToActionV1 />
        <Footer />
    </div>
}

export default HowToBeDriver

